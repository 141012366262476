
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.listing-block {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px;
    position: relative;
    transition: all 0.2s ease-in-out;
    padding-top: 40px;
    // border-bottom-width: 0;
    &:first-child {
    }
    // @include handleAllBreakpoints("border", "offerBorder", "1px solid #cfcfcf");
    @include handleAllBreakpoints(
        "border-bottom",
        "offerBorderBottom",
        "1px solid #cfcfcf"
    );
    @include handleAllBreakpoints(
        "border-top",
        "offerBorderTop",
        "1px solid #cfcfcf"
    );
    @include handleAllBreakpoints(
        "border-right",
        "offerBorderRight",
        "1px solid #cfcfcf"
    );
    @include handleAllBreakpoints(
        "border-left",
        "offerBorderLeft",
        "1px solid #cfcfcf"
    );
    @include handleAllBreakpoints("min-height", "offerMinHeight", "220px");
    @include handleAllBreakpoints("border-radius", "offerBorderRadius", "0");
    @include handleAllBreakpoints("background", "offerBackgroundColor", "#fff");
    @include handleAllBreakpoints("box-shadow", "offerBoxShadow", "none");
    .bannerFeaturedOfferMessage {
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% + 12px);
        height: calc(100% + 40px);
        @include handleAllBreakpoints(
            "color",
            "featuredOfferBannerTextColor",
            "#fff"
        );
        border-radius: 15px;
        z-index: -1;

        @include handleAllBreakpoints(
            "background",
            "featuredOfferBannerBackgroundColor",
            "#ff5b19"
        );

        span {
            text-align: center;
            display: block;
            font-size: 16px;
            font-weight: 700;
            padding: 5px;
        }
    }
    .order {
        position: absolute;
        left: 0;
        top: 0;
        @include handleAllBreakpoints(
            "background-color",
            "buttonBackgroundColor",
            "#3ea636",
            "!important"
        );

        color: white;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 0 4px 0;
        transition: all 0.2s ease-in-out;
        @apply text-sm;
    }
    .rating {
        font-size: 18px;
        font-weight: bold;
        display: none;
        gap: 10px;
        align-items: center;
        flex-direction: column;
        align-items: flex-end;
        @include lg(offers) {
            flex-direction: row;
            align-items: center;
        }

        .worded {
            font-size: 14px;
            @include lg(offers) {
                font-size: 20px;
            }
            @include handleAllBreakpoints("color", "ratingTextColor", "#000");
        }

        .numbered {
            width: 42px;
            height: 35px;
            padding: 5px;
            display: grid;
            place-items: center;
            border-radius: 5px;
            // background-color: #f0f0f0;
            @include handleAllBreakpoints(
                "background-color",
                "ratingBackgroundColor",
                "#f0f0f0"
            );
            @include handleAllBreakpoints("color", "ratingGradeColor", "#000");
        }
    }
    .ratingMiddle {
        width: fit-content;
        margin-top: 20px;
        .rating {
            @include lg(offers) {
                display: flex;
                flex-direction: column-reverse;
                justify-content: center;
                gap: 0;
                width: 100%;
                min-width: 95px;
                min-height: 95px;
                border-radius: 15px;
                // background: #f6f3ff;
                @include handleAllBreakpoints(
                    "background-color",
                    "ratingBackgroundColor",
                    "#f6f3ff"
                );
            }
            .worded {
                @include lg(offers) {
                    // color: #53379c;
                    font-size: 12px;
                    font-weight: 700;
                }
            }

            .numbered {
                @include lg(offers) {
                    width: unset;
                    height: unset;
                    padding: unset;
                    display: unset;
                    place-items: unset;
                    border-radius: unset;
                    background-color: unset;
                    font-size: 35px;
                    // color: #53379c;
                    font-weight: bold;
                    line-height: 35px;
                }
            }
        }
    }
    .middleWithSeparator {
        .rating {
            @include lg(offers) {
                position: relative;
                background: unset;
            }
            .worded {
                @include lg(offers) {
                    // color: unset;
                    font-size: 14px;
                }
            }

            .numbered {
                @include lg(offers) {
                    // color: unset;
                }
            }

            &::before {
                content: unset;
                position: absolute;
                width: 1px;
                height: 150px;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                background-color: #e2e2e2;
                @include lg(offers) {
                    content: " ";
                }
            }
            &::after {
                content: unset;
                position: absolute;
                width: 1px;
                height: 150px;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                background-color: #e2e2e2;
                @include lg(offers) {
                    content: " ";
                }
            }
        }
    }
    .logo {
        display: flex;
        justify-content: space-between;
        width: 100%;
        img {
            width: 70%;
            max-width: 300px;
        }

        .rating {
            display: flex;
        }

        @include lg(offers) {
            .rating {
                display: none;
            }

            width: 30%;
            padding: 0 40px;
            img {
                width: 100%;
            }
        }
    }
    .content {
        padding-right: 0;
        width: 100%;
        margin-top: 10px;
        .title {
            @include handleAllBreakpoints(
                "font-weight",
                "descriptionWeight",
                "bold"
            );
            @include handleAllBreakpoints(
                "font-size",
                "descriptionSize",
                "1rem::1.25rem"
            );
            @include handleAllBreakpoints("color", "descriptionColor", "black");
            margin-bottom: 1rem;
        }
        .features {
            ul {
                display: flex;
                flex-direction: column;
                li {
                    // text-sm font-light mb-2 flex items-start
                    align-items: flex-start;
                    display: flex;
                    font-weight: 300;
                    @include handleAllBreakpoints(
                        "font-size",
                        "featureTextSize",
                        "0.875rem"
                    );
                    @include handleAllBreakpoints(
                        "color",
                        "featureColor",
                        "#5B5B5B"
                    );
                    svg {
                        transform: translateY(5px);
                        @include handleAllBreakpoints(
                            "fill",
                            "buttonBackgroundColor",
                            "#3ea636"
                        );
                    }
                }
            }
        }
        @include lg(offers) {
            padding-right: 20px;
            width: 45%;
        }
    }
    .contentShowSeparator {
        position: relative;
        &::before {
            content: unset;
            position: absolute;
            width: 1px;
            height: 150px;
            left: -20px;
            top: 50%;
            transform: translateY(-50%);
            background-color: #e2e2e2;
            @include lg(offers) {
                content: " ";
            }
        }
    }
    .contentWithRatingMiddle {
        @include lg(offers) {
            width: 39%;
        }
    }
    .cta {
        width: 100%;
        min-width: fit-content;
        text-align: right;
        display: grid;
        gap: 10px;
        --min: 17ch;
        --gap: 10px;
        order: 5;

        display: grid;
        grid-gap: var(--gap);
        grid-template-columns: repeat(
            auto-fit,
            minmax(min(100%, var(--min)), 1fr)
        );

        margin-top: 20px;

        a.button {
            @include handleAllBreakpoints("color", "buttonTextColor", "white");

            @include handleAllBreakpoints(
                "box-shadow",
                "buttonBoxShadow",
                "5px 5px 0px #dfdfdf"
            );
            @include handleAllBreakpoints(
                "background-color",
                "buttonBackgroundColor",
                "#3ea636"
            );
            @include handleAllBreakpoints(
                "border-radius",
                "buttonBorderRadius",
                "5px"
            );
            @include handleAllBreakpoints("border", "buttonBorder", "none");

            &:hover {
                @include handleAllBreakpoints(
                    "background-color",
                    "buttonHoverBackgroundColor",
                    "#3ea636"
                );
            }

            &::before {
                content: unset;
            }
            .check {
                width: 27px;
                height: 27px;
                border: 2px solid white;
                @include handleAllBreakpoints(
                    "border-color",
                    "buttonTextColor",
                    "white"
                );

                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 auto;
                svg {
                    height: 13px;
                    width: 13px;
                    @include handleAllBreakpoints(
                        "fill",
                        "buttonTextColor",
                        "white"
                    );
                }
            }
            @include lg(offers) {
                width: 100%;
                padding: 15px 30px;

                &::before {
                    content: "";
                }
            }
        }
        a.link {
            @include handleAllBreakpoints("color", "linkTextColor", "#0e5aa9");

            @include handleAllBreakpoints(
                "box-shadow",
                "linkBoxShadow",
                "none"
            );
            @include handleAllBreakpoints(
                "background-color",
                "linkBackgroundColor",
                "none"
            );
            @include handleAllBreakpoints(
                "border-radius",
                "linkBorderRadius",
                "none"
            );
            @include handleAllBreakpoints("border", "buttonBorder", "none");

            &:hover {
                @include handleAllBreakpoints(
                    "background-color",
                    "linkHoverBackgroundColor",
                    "none"
                );
            }

            &::before {
                content: "";
            }

            @include lg(offers) {
                width: 100%;
                padding: 15px 30px;
            }
        }
        .featured-offer-message {
            text-align: center;
            max-width: 235px;
            // word-wrap: ;
            font-size: 11px;
            display: block;
            margin: 0 auto;
            margin-top: 10px;
            color: #817f7f;
            text-transform: capitalize;

            font-weight: 300;

            transition: all 0.4s ease-in-out;
            @include sm {
                font-size: 13px;
            }
            @include lg(offers) {
                font-size: 14px;
                margin-top: 15px;
                opacity: 0;
                transform: translateX(250px);
            }
        }
        &.clicked {
            a {
                padding: 10px 30px;
            }
        }
        a {
            display: block;
            text-align: center;
            padding: 12px 10px;
            transition: all 0.2s ease-in-out;

            @include handleAllBreakpoints(
                "font-weight",
                "buttonTextWeight",
                "normal"
            );
            @include handleAllBreakpoints(
                "font-size",
                "buttonTextSize",
                "14px"
            );

            min-width: fit-content;
        }
        @include lg(offers) {
            display: flex;
            width: 20%;
            flex-direction: column;
            padding-right: 5px;
            margin-left: auto;
            order: unset;
            .rating {
                display: flex;
                margin-left: auto;
                margin-bottom: 20px;
            }
        }
    }

    &.featured {
        // @include handleAllBreakpoints(
        //     "border",
        //     "featuredOfferBorder",
        //     "2px solid #d5d5d5"
        // );
        @include handleAllBreakpoints(
            "border-left",
            "featuredOfferBorderLeft",
            "2px solid #d5d5d5"
        );
        @include handleAllBreakpoints(
            "border-right",
            "featuredOfferBorderRight",
            "2px solid #d5d5d5"
        );
        @include handleAllBreakpoints(
            "border-top",
            "featuredOfferBorderTop",
            "2px solid #d5d5d5"
        );
        @include handleAllBreakpoints(
            "border-bottom",
            "featuredOfferBorderBottom",
            "2px solid #d5d5d5"
        );
        @include handleAllBreakpoints(
            "background-color",
            "featuredOfferBackgroundColor",
            "rgba(#f5f5f5, 0.8)"
        );
        .order {
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                width: 18px;
                height: 18px;
                position: absolute;
                left: 8px;
                top: 8px;
                fill: white;
            }
        }
        .logo {
            mix-blend-mode: multiply;
        }
    }

    &:hover {
        @include handleAllBreakpoints(
            "box-shadow",
            "offerHoverBoxShadow",
            "0px 0px 50px -20px #00000061"
        );
        @include handleAllBreakpoints(
            "border-color",
            "offerHoverBorderColor",
            "#9a9a9a"
        );

        &:not(:first-child) {
            .order {
                background-color: $insurance-super-saver-assault;
            }
        }
        &.featured {
            .cta {
                .featured-offer-message {
                    @include lg(offers) {
                        transform: translateX(0);
                        opacity: 1;
                    }
                }
            }
        }
    }

    @include lg(offers) {
        padding: 20px 30px;
    }

    .offer-index {
        top: 10px;
        left: 15px;
        display: flex;
        gap: 10px;
        position: absolute;
        align-items: center;
        font-size: 22px;
        font-weight: bold;
        @include handleAllBreakpoints("color", "indexTextColor", "#dedede");
        @include lg(offers) {
            font-size: 35px;
            top: 5px;
        }

        .featured-offer-message-top {
            font-size: 14px;
            @include handleAllBreakpoints(
                "color",
                "buttonBackgroundColor",
                "#3ea636"
            );
        }

        @include lg(offers) {
        }
    }

    .summary-container {
        width: 100%;
        padding: 10px 0 5px 0;
        margin-top: 25px;
        border-top: 1px solid #d9d9d9;
        .summary {
            display: none;
            font-weight: 300;
            order: 4;
            margin-top: 20px;
            @include handleAllBreakpoints(
                "font-size",
                "summaryFontSize",
                "12px::14px"
            );
            @include lg(offers) {
                margin-top: 0px;
                order: unset;
                display: block;
            }
        }

        .summary.expanded {
            display: block;
        }

        .summary-toggle {
            cursor: pointer;
            font-size: 16px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include lg(offers) {
                display: none;
            }

            .chevron-up {
                transform: rotate(90deg);
            }
            .chevron-down {
                transform: rotate(-90deg);
            }
        }

        @include lg(offers) {
            padding: 30px 0 20px 0;
            margin-top: 30px;
        }
    }
}
.listingBlockOverflow {
    overflow: visible;
}

@keyframes blink {
    0% {
        transform: skewX(-45deg) translateX(-130%);
    }

    100% {
        transform: skewX(-45deg) translateX(20rem);
    }
}

.sectionTitle {
    line-height: normal;
    margin-bottom: 1.5rem;
    @include handleAllBreakpoints(
        "font-size",
        "titleFontSize",
        "24px::var(--global-sections-typography-title-size)"
    );
    @include handleAllBreakpoints("color", "titleFontColor", "#424242");
    @include handleAllBreakpoints("font-weight", "titleFontWeight", "500");
    @include handleAllBreakpoints(
        "text-align",
        "titleAlign",
        "var(--global-sections-typography-title-align)"
    );
    @include handleAllBreakpoints("margin", "titleMargin", "0 0 2.5rem 0");
}

.container {
    display: flex;
    flex-direction: column;
    container-type: inline-size;
    container-name: offers;
    @include handleAllBreakpoints("gap", "offersGap", "0px");
}
